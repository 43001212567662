import spotifyLogo from "./spotify-icon.svg";
import youtubeLogo from "./youtube-icon.svg";

import css from "./LandingPage.module.css";

function LandingPage() {
  return (
    <div className={css.landingPage}>
      <div className={css.loginContainer}>
        <span className={css.titleContainer}>KojoSpot</span>
        <div className={css.imageContainer}>
          <img src={spotifyLogo} className={css.logo} />
          <img src={youtubeLogo} className={css.logo} />
        </div>
        <div className={css.buttonContainer}>
          <a
            href={`${process.env.REACT_APP_BACK_END_URL}/api/auth/login`}
            className={css.linkButton}
          >
            <button className={css.loginButton}>Login With Spotify</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
