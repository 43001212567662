import { useState, useEffect, useRef } from "react";

import YouTubePlayer from "youtube-player";
import Spotify from "spotify-web-api-js";

import repeatIcon from "./repeatIcon.png";
import shuffleIcon from "./shuffleIcon.png";
import spotifyLogo from "./spotifyLogo.png";

import { getRefreshToken } from "../../util/api";
import { convertRemToPixels } from "../../util/functions";

import css from "./DynamicPage.module.css";
import Navigation from "../../components/Navigation/Navigation";

const REFRESH_SPEED = 2000;
const NON_PLAYER_REM_HEIGHT = 20;
type Track = {
  trackName: string;
  artist: string;
  youtubeID: string;
  youtubeLyricID: string;
  spotifyID: string;
};

type PlaybackState = {
  currentlyPlaying: Promise<SpotifyApi.CurrentPlaybackResponse>;
  recentlyPlayed?: Promise<SpotifyApi.UsersRecentlyPlayedTracksResponse>;
};

function Dynamic() {
  const player = useRef<ReturnType<typeof YouTubePlayer> | null>(null);
  const [lyric, setLyric] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  const [queryID, setQueryID] = useState<Track>({
    trackName: "Loading...",
    artist: "",
    youtubeID: "qxOkaU6RVz4",
    youtubeLyricID: "qxOkaU6RVz4",
    spotifyID: "",
  });

  const [fetching, setFetching] = useState<boolean>(false);
  const [firstTrackPlayed, setFirstTrackPlayed] = useState<boolean>(false);
  // const [toUnMute, setToUnmute] = useState<Boolean>(false);

  const otherTypeOfVideo: string = lyric ? "Music Video" : "Lyric Video";

  const trackName: string = fetching
    ? "Loading..."
    : lyric
    ? queryID.trackName + " (Lyrics)"
    : queryID.trackName;

  // Set Access Token for Use
  const spotifyWebApi = new Spotify();
  const access_token = window.localStorage.getItem("access_token");
  spotifyWebApi.setAccessToken(access_token);

  /*  ----------------- INITIALISE PLAYER  ----------------- */
  const onPlayerReady = (e: any) => {
    e.target.playVideo();
  };

  const onPlayerStateChange = (e: any) => {
    if (e.data === -1) {
    }
    if (e.data === window.YT.PlayerState.PLAYING) {
    }
    if (e.data === window.YT.PlayerState.PAUSED) {
    }
    if (e.data === window.YT.PlayerState.ENDED) {
      player.current?.loadVideoById(queryID.youtubeID);
    }
  };

  useEffect(() => {
    const maxHeight = windowHeight - convertRemToPixels(NON_PLAYER_REM_HEIGHT);
    player.current = YouTubePlayer("player", {
      width: Math.max(200, windowWidth * 0.8),
      height: Math.min(Math.max(200, windowWidth * 0.8 * 0.562), maxHeight),

      playerVars: {
        autoplay: 1,
        controls: 1,
        disablekb: 0,
        enablejsapi: 1,
        modestbranding: 1,
        loop: 1,
        rel: 0,
      },
    });
  }, []);

  useEffect(() => {
    let cb = function () {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", cb);

    return () => {
      window.removeEventListener("resize", cb);
    };
  }, []);

  useEffect(() => {
    const maxHeight = windowHeight - convertRemToPixels(NON_PLAYER_REM_HEIGHT);
    player.current?.setSize(
      Math.max(200, windowWidth * 0.8),
      Math.min(Math.max(200, windowWidth * 0.8 * 0.562), maxHeight)
    );
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    player.current?.on("ready", (event) => {
      onPlayerReady(event);
    });
    player.current?.on("stateChange", (event) => {
      onPlayerStateChange(event);
    });
  }, [queryID]);

  /*  ------------------------------------------------------ */

  /// Run Interval
  useEffect(() => {
    const interval = setInterval(() => {
      checkPlaybackState();
    }, REFRESH_SPEED);

    return () => clearInterval(interval);
  }, [JSON.stringify(queryID)]);

  const checkPlaybackState = async () => {
    const promises: PlaybackState = {
      currentlyPlaying: spotifyWebApi.getMyCurrentPlaybackState(),
    };

    if (!firstTrackPlayed) {
      promises.recentlyPlayed = spotifyWebApi.getMyRecentlyPlayedTracks();
    }

    try {
      const userPlaybackPromises = await Promise.all([
        promises.currentlyPlaying,
        promises.recentlyPlayed,
      ]);

      let name = "";
      let artists: SpotifyApi.ArtistObjectSimplified[] = [];
      let id = "";
      let artist: string;
      let track: string;
      let youtubeID: string;
      let youtubeLyricID: string;
      let spotifyID: string;

      if (userPlaybackPromises[0]?.item) {
        let { device, item } = userPlaybackPromises[0];
        ({ name, artists, id } = item);

        const { shuffle_state, repeat_state, is_playing } =
          userPlaybackPromises[0];

        if (is_playing) {
          spotifyWebApi.pause();
        }
        const player_state = await player.current?.getPlayerState();

        if (shuffle_state && player_state === 1) {
          player.current?.pauseVideo();
        } else if (!shuffle_state && player_state === 2)
          player.current?.playVideo();

        if (repeat_state === "context") {
          spotifyWebApi.setRepeat("off");
          setLyric((prevLyric) => !prevLyric);
        }
      } else if (userPlaybackPromises[1]) {
        ({ name, artists, id } = userPlaybackPromises[1].items[0].track);
      }

      const trackName = name && artists && name + " - " + artists[0].name;
      if (id && queryID.spotifyID != id) {
        setFetching(true);
        const res = await getYoutubeID(trackName, id);
        [youtubeID, youtubeLyricID, spotifyID] = [
          res.youtubeID,
          res.youtubeLyricID,
          id,
        ];

        setQueryID((prevState) => {
          return {
            ...prevState,
            artist: artists[0].name,
            trackName: name,
            youtubeID,
            youtubeLyricID,
            spotifyID,
          };
        });
        setFetching(false);
        setFirstTrackPlayed(true);
      }
    } catch (err) {
      console.log(err);
      console.log("API key expired in setup state.");
      const refresh_token = window.localStorage.getItem("refresh_token");
      const { access_token } = await getRefreshToken(refresh_token);
      window.localStorage.setItem("access_token", access_token);
      spotifyWebApi.setAccessToken(access_token);
    }
  };

  const getYoutubeID = async (trackName: string, spotifyID: string) => {
    // Creating a signal so we can abort the fetch request when we unmount
    const controller = new AbortController();
    const signal = controller.signal;

    // Give the server a list of track objects you want to search, and your current list of query and lyric IDs

    const params = new URLSearchParams({
      trackName,
      spotifyID,
    });

    const response = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/api/youtube_search/google_api?` +
        params,
      {
        method: "GET",
        signal: signal,
      }
    );

    const { youtubeID, youtubeLyricID } = await response.json();

    setLyric(false);
    return { youtubeID, youtubeLyricID };
  };

  useEffect(() => {
    // Load on change video

    if (player.current) player.current.loadVideoById(queryID.youtubeID);
  }, [JSON.stringify(queryID)]);

  useEffect(() => {
    // Load on change lyric
    if (player.current) {
      lyric
        ? player.current.loadVideoById(queryID.youtubeLyricID)
        : player.current.loadVideoById(queryID.youtubeID);
    }
  }, [lyric]);

  return (
    <div className={css.fullScreen}>
      <Navigation back="modes" />

      <span className={css.trackName}>
        {" "}
        {queryID.trackName}
        {lyric ? " (Lyrics)" : null}
      </span>
      <span className={css.artist}> {queryID.artist}</span>
      <div className={css.playerContainer}>
        <div id="player"></div>
      </div>
      <div className={css.instructions}>
        <div className={css.instruction}>
          <img src={shuffleIcon} className={css.spotifyIcon} />
          <span className={css.instructionText}>
            {" "}
            Shuffle on app to pause/play{" "}
          </span>
        </div>
        <div className={css.instruction}>
          <img src={repeatIcon} className={css.spotifyIcon} />
          <span className={css.instructionText}>
            {" "}
            Repeat on app to change to {otherTypeOfVideo}{" "}
          </span>
        </div>
        {/* <div className={css.instruction}>
          <img src={spotifyLogo} className={css.spotifyLogo} />
          <span className={css.instructionText}>
            {" "}
            Try changing songs on the app!
          </span>
        </div> */}
      </div>
    </div>
  );
}

export default Dynamic;
