import ModeCard from "../../components/ModeCard/ModeCard";

import dynamicImg from "./dynamic.jpg";
import playlistImg from "./playlist.jpg";

import Navigation from "../../components/Navigation/Navigation";

import css from "./ModesPage.module.css";

function Modes(props: any) {
  const query = new URLSearchParams(props.location.search);
  const access_token = query.get("access_token");
  const refresh_token = query.get("refresh_token");
  if (access_token) localStorage.setItem("access_token", access_token);
  if (refresh_token) localStorage.setItem("refresh_token", refresh_token);
  return (
    <div className={css.modesPage}>
      <Navigation back="login" />
      <div className={css.titleContainer}>Mode Selection</div>
      <div className={css.modesContainer}>
        <ModeCard
          id="dynamic"
          img={dynamicImg}
          descriptionText="Control Youtube from your Spotify app"
          disabled={false}
        />
        <ModeCard
          id="playlist"
          img={playlistImg}
          descriptionText={
            "Watch videos from your Spotify playlist" +
            "\n\n" +
            "** COMING SOON **"
          }
          disabled={true}
        />
      </div>
    </div>
  );
}

export default Modes;
