import clsx from "clsx";
import css from "./ModeCard.module.css";
import { ConditionalLink } from "../../util/components";

interface Props {
  id: string;
  img: string;
  descriptionText?: string;
  disabled: boolean;
}

function ModeCard(props: Props) {
  const { id, img, descriptionText, disabled } = props;
  return (
    <div className={clsx(css.modeCardContainer)}>
      <div className={clsx(css.modeCard)}>
        <ConditionalLink condition={!disabled} to={`/${id}`}>
          <button className={clsx(css.modeButton, disabled && css.disabled)}>
            {" "}
            {id}{" "}
          </button>
          <div className={css.modeCardInner}>
            <div className={clsx(css.modeCardFront, disabled && css.disabled)}>
              <img className={css.modeImage} src={img} alt="" />
            </div>
            <div className={clsx(css.modeCardBack, disabled && css.disabled)}>
              <span className={css.modeCardBackText}>{descriptionText} </span>
            </div>
          </div>
        </ConditionalLink>
      </div>
    </div>
  );
}

export default ModeCard;
