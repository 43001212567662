import React from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';

const PrivateRoute : React.FC<RouteProps> = ({ component: Component , ...rest }) => {
    if (!Component) return null;
    const access_token = localStorage.getItem('access_token');
    if (!access_token) return <Redirect to="/" />
    return <Route {...rest } component={(props: RouteComponentProps) => <Component {...props} /> } />

}

export default PrivateRoute